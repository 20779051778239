.cookieContainer {
  z-index: 999;
  width: 350px;
  min-height: 20px;
  box-sizing: border-box;
  padding: 30px 30px 30px 30px;
  background: #232323;
  overflow: hidden;
  position: fixed;
  bottom: 30px;
  /* right: 30px; */
  left: 30px;
  display: none;
}

.cookieContainer .interviewTitle a {
  font-family: OpenSans, arial, "sans-serif";
  color: #FFFFFF;
  font-size: 22px;
  line-height: 20px;
  display: block;
}

.cookieContainer .interviewDesc p {
  margin: 0;
  padding: 0;
  font-family: OpenSans, arial, "sans-serif";
  color: #FFFFFF;
  font-size: 13px;
  line-height: 20px;
  display: block;
  margin-top: 10px;
}

.cookieContainer .interviewDesc a {
  font-family: OpenSans, arial, "sans-serif";
  color: #FFFFFF;
  text-decoration: underline;
}

.cookieContainer .cookieButtonY a {
  display: block;
  font-family: OpenSans, arial, "sans-serif";
  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
  margin-top: 14px;
  background: #000000;
  box-sizing: border-box;
  padding: 15px 24px;
  text-align: center;
  transition: background 0.3s;
}

.cookieContainer .cookieButtonY a:hover {
  cursor: pointer;
  transition-delay: 0.3s;
  -webkit-transition-delay: 0.3s;
  background: #f8c331;
}

.cookieContainer .cookieButtonN a {
  display: block;
  font-family: OpenSans, arial, "sans-serif";
  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
  margin-top: 14px;
  background: #000000;
  box-sizing: border-box;
  padding: 15px 24px;
  text-align: center;
  transition: background 0.3s;
}

.cookieContainer .cookieButtonN a:hover {
  cursor: pointer;
  transition-delay: 0.3s;
  -webkit-transition-delay: 0.3s;
  background: #791718;
}

@media (max-width: 980px) {
  .cookieContainer {
    bottom: 0px !important;
    left: 0px !important;
    width: 100% !important;
  }
}