

.roll-error {
    color: red;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 20px;
}

.roll-start {
    margin-top: 82px;
    margin-bottom: 38px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.2s;
}

.roll-start img {
    width: 416px;
}

.roll-open {
    display: flex;
    justify-content: center;
}

.roll-open button {
    width: 288px;
    height: 65px;
    background: #FFD900;
    border-radius: 8px;
    font-family: 'Hanson', sans-serif;
    font-size: 32px;
    font-weight: 700;
    color: #101012;
    text-transform: uppercase;
}

.roll-open button:disabled {
    background: #d0ccd4;
}



.roll-process {
    margin-top: 122px;
    width: 100%;
    height: 185px;
    margin-bottom: 76px;
    overflow: hidden;
    position: relative;
    transition: 0.2s;
}

.roll-list {
    position: absolute;
    display: flex;
}

.roll-overlay {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, #16161b, transparent, transparent, transparent, transparent, transparent, #16161b);
}

.roll-handle {
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 4px;
    height: 100%;
    background: #FFD900;
    filter: drop-shadow(#FFD900 0px 4px 10px);
}

.roll-item {
    height: 185px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    position: relative;
    background-image: linear-gradient(to right bottom, rgba(38, 42, 50, 1), rgba(38, 42, 50, 0.75), rgba(38, 42, 50, 0.5), rgba(38, 42, 50, 0.25), rgba(38, 42, 50, 0.1));
}

.roll-item img {
    height: 130px;
    max-width: 133px;
}

.roll-item .hr {
    width: 86px;
    height: 1px;
    margin-left: auto;
    margin-right: auto;
    bottom: 1px;
}

.roll-item p {
    font-size: 12px;
    font-weight: 500;
    color: #FFFFFF;
    margin-top: 9px;
    text-align: center;
}

.roll-item .shadow {
    width: 56px;
    height: 28px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    filter: blur(52px);
}

.roll-result {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 122px;
    margin-bottom: 76px;
    transition: 0.2s;
}

.roll-result-side-1 {
    position: absolute;
    top: -56px;
    left: 116px;
}

.roll-result-side-2 {
    position: absolute;
    top: -56px;
    right: 116px;
    transform: scale(-1);
}






@media screen and (max-width: 1232px) {
    .roll-result-side-1 {
        left: -390px;
    }
    .roll-result-side-2 {
        right: -390px;
    }

    .roll-process {
        width: 1232px;
        position: absolute;
    }
    .roll-zone {
        width: 100%;
        height: calc(263px + 82px + 38px);
        display: flex;
        justify-content: center;
    }
}


@media screen and (max-width: 500px) {
    .roll-result-side-1 {
        display: none;
    }
    .roll-result-side-2 {
        display: none;
    }
    .roll-start img {
        width: 263px;
    }
    .roll-start {
        margin-bottom: 32px;
    }
    .roll-open button {
        width: 219px;
        font-size: 24px;
    }
    .roll-zone {
        height: calc(166px + 82px + 38px);
    }
    .roll-process {
        margin-top: 60px;
    }
    .roll-result {
        margin-top: 60px;
    }
}