

@media screen and (max-width: 1000px) {
    header .right {
        display: none;
    }
    header .menu {
        display: flex;
    }
    .latest .item .image {
        width: 72%;
        height: auto;
    }
    .latest .pages {
        justify-content: center;
    }

    footer {
        flex-direction: column;
        align-items: center;
        gap: 48px;
    }
    footer nav {
        margin-left: unset;
    }
    .footer-text {
        margin-left: unset;
        margin-right: unset;
    }
    .latest {
        height: auto;
    }
}

@media screen and (max-width: 700px) {
    .latest .title .left p {
        font-size: 14px;
    }
    .latest .title .right {
        font-size: 12px;
    }
    .latest .list {
        width: 500px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 35px;
    }
}

@media screen and (max-width: 540px) {
    .latest .list {
        width: 350px;
        padding-left: 35px;
    }
}

@media screen and (max-width: 500px) {
    .description {
        margin-top: 32px;
        margin-bottom: 127px;
    }

    .description .title {
        font-size: 40px;
    }

    .description .text {
        font-size: 16px;
    }
    .description img {
        width: 262px;
    }
    .latest {
        padding-right: 16px;
        margin-bottom: 32px;
    }
    .latest .list {
        padding-left: 16px;
    }

    footer .logo {
        flex-direction: column;
        align-items: center;
        gap: 48px;
    }
    footer nav {
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (max-width: 450px) {
    .latest .title .right {
        max-width: 124px;
        text-align: right;
    }
    .latest .pages button {
        width: 8px;
    }
}

@media screen and (max-width: 370px) {
    .latest .list {
        width: 165px;
        padding-left: 16px;
    }
}