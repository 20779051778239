.modal-container {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
    transition: 0.3s;
}

.modal-background {
    background: rgba(0, 0, 0, 0.5);
}

.modal {
    transition: 0.3s;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 22px;
    background-image: linear-gradient(to right, #1a1c21, #191a20, #18191e, #17181d, #16161b);
    border: 1px solid rgba(255, 255, 255, 0.25);
}

.modal[data-modal="wrong"] {
    width: 674px;
    height: 500px;
    padding-top: 69px;
}

.modal .close {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    position: absolute;
    top: 16px;
    right: 16px;
}

.modal-header .close img {
    width: 16px;
}


.modal-title {
    font-size: 73px;
    font-weight: 900;
    color: #FFFFFF;
    text-transform: uppercase;
}

.modal-subtitle {
    font-size: 36px;
    font-weight: 900;
    color: #FFFFFF;
    text-transform: uppercase;
}

.modal-text {
    font-size: 25px;
    color: #7D8999;
    text-align: center;
    margin-top: 25px;
}

.modal-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 19px;
    margin-top: 25px;
}

.modal-buttons button {
    width: 420px;
    height: 78px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 13px;
    border-radius: 11px;
}

.modal-buttons button img {
    width: 26px;
}

.modal-buttons button p {
    font-size: 25px;
    font-weight: 900;
    color: #101012;
}

.modal-buttons button.signin {
    background: #FFD900;
}

.modal-buttons button.cancel {
    background: #d0ccd4;
}

@media screen and (max-width: 700px) {
    .modal {
        width: 90%;
    }
    .modal[data-modal="wrong"] {
        padding-top: 32px;
        width: 311px;
        height: 230px;
    }
    .modal-title {
        font-size: 33px;
    }
    .modal-subtitle {
        font-size: 16px;
    }
    .modal-text {
        font-size: 12px;
        margin-top: 9px;
    }
    .modal-buttons {
        gap: 9px;
        margin-top: 8px;
    }
    .modal-buttons button {
        width: 194px;
        height: 36px;
        border-radius: 5px;
        gap: 6px;
    }
    .modal-buttons button p {
        font-size: 12px;
    }
    .modal-buttons button img {
        width: 12px;
    }
    .modal .close img {
        width: 18px;
    }
    .modal .close {
        top: 8px;
        right: 8px;
    }
}