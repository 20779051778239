

header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 34px;
    padding-bottom: 34px;
}

header .logo, footer .logo {
    display: flex;
    align-items: center;
    gap: 11px;
}

header .logo img, footer .logo img {
    width: 43px;
}

header .logo p, footer .logo p {
    font-size: 24px;
    font-weight: 700;
}

header .right {
    display: flex;
    align-items: center;
}

header .right nav, footer nav {
    display: flex;
    align-items: center;
    gap: 32px;
}

header .right nav a, footer nav a {
    font-size: 16px;
}

header .right .hr {
    margin-left: 32px;
    margin-right: 32px;
    width: 1px;
    height: 60px;
    background: #1F2937;
}

header .signin {
    font-size: 16px;
    margin-right: 32px;
}

.signup {
    padding: 0 32px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: #FFD900;
}

.signup img {
    width: 15.67px;
}

.signup p {
    font-size: 16px;
    color: #101012;
}

header .menu {
    display: none;
    justify-content: center;
    align-items: center;
    background: transparent;
    padding: 0;
}

header .menu img {
    width: 32px;
}




.description {
    margin-top: 80px;
    margin-bottom: 204px;
    max-width: 966px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.description .title {
    font-size: 53px;
    font-weight: 700;
    color: #FFFFFF;
    max-width: 900px;
}

.hidden {
    display: none;
}
.description .title .name {
    color: #FFD900;
}

.description .text {
    font-size: 24px;
    color: #7D8999;
    margin-top: 30px;
}

.description .text .b {
    font-weight: 700;
}

.description img {
    margin-top: 30px;
    width: 321px;
}
.description .list {
    list-style-type: disc;
    padding-left: 40px;
    text-align: left;
    margin-top: 30px;
}

.description .list-item {
    font-size: 24px;
    color: #7D8999;
    margin-bottom: 15px;
}


.terms-heading {
    color: white;
  }
  .terms-list {
    color: white;
  }




.latest {
    width: 100%;
    height: 290px;
    margin-bottom: 85px;
    border-radius: 37px;
    background-image: linear-gradient(to bottom, #1a1c21, #191a20, #18191e, #17181d, #16161b);
    padding-right: 35px;
}

.latest .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.latest .title .left {
    display: flex;
    align-items: center;
}

.latest .title .left img {
    width: 66px;
}

.latest .title .left p {
    font-size: 23px;
    font-weight: 700;
    text-transform: uppercase;
}

.latest .title .left p .c {
    color: #FFD900;
}

.latest .title .right {
    font-size: 16px;
    color: #7D8999;
}

.latest .title .right .v {
    color: #fff;
}


.latest .list {
    padding-left: 26px;
    margin-top: 8px;
}

.latest .item {
    width: 147px;
    height: 160px;
    background: #262A32;
    border-radius: 14px;
    padding-top: 30px;
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.latest .item .shadow {
    width: 86px;
    height: 1px;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 1px;
}

.latest .item .time {
    font-size: 8px;
    font-weight: 500;
    color: #858B98;
    position: absolute;
    top: 9px;
    right: 9px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.latest .item .image {
    max-width: 107px;
    height: 84px;
}

.latest .item .name {
    width: 100%;
}

.latest .item .name p:nth-child(1) {
    font-size: 10px;
    color: #858B98;
    margin-top: 6.58px;
}

.latest .item .name p:nth-child(2) {
    font-size: 10px;
    color: #FFFFFF;
}



.latest .pages {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    margin-top: 29px;
}

.latest .pages button {
    width: 40px;
    height: 8px;
    border-radius: 10px;
    background: #FFD900;
    padding: 0;
}

.latest .pages ul {
    list-style: none;
    padding-left: 0;
    display: flex;
    align-items: center;
    gap: 10px;
}

.latest .pages ul button {
    font-size: 0;
}

.latest .pages .slick-active button {
    background: #7D8999;
    cursor: default;
}


footer {
    display: flex;
    align-items: center;
    padding-top: 64px;
    padding-bottom: 60px;
}

footer nav {
    margin-left: 62px;
}

.footer-text {
    margin-left: auto;
    margin-right: 0;
    font-size: 14px;
    color: #4B5563;
}


.menu-bar {
    position: absolute;
    width: 100%;
    padding: 32px;
    background: #16161B;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    transition: 0.2s;
    z-index: 2;
}

.menu-bar nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
}

.menu-bar nav a {
    font-size: 16px;
}


.backlight-1 {
    position: absolute;
    width: 818px;
    top: -393px;
    left: -610px;
}

.backlight-2 {
    position: absolute;
    width: 818px;
    bottom: 0;
    right: -800px;
}