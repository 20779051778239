/* @font-face {
    font-family: 'Hanson';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(../fonts/Hanson-Bold.ttf) format('truetype');
} */
@font-face {
    font-family: 'Mazzard';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(../fonts/MazzardH-Regular.otf) format('opentype');
}
@font-face {
    font-family: 'Mazzard';
    font-style: normal;
    font-weight: 450;
    font-display: swap;
    src: url(../fonts/MazzardM-Regular.otf) format('opentype');
}
@font-face {
    font-family: 'Mazzard';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(../fonts/MazzardM-Medium.otf) format('opentype');
}
@font-face {
    font-family: 'Mazzard';
    font-style: normal;
    font-weight: 550;
    font-display: swap;
    src: url(../fonts/MazzardH-Medium.otf) format('opentype');
}
@font-face {
    font-family: 'Mazzard';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(../fonts/MazzardH-Bold.otf) format('opentype');
}
@font-face {
    font-family: 'Mazzard';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(../fonts/MazzardL-Black.otf) format('opentype');
}

html, body {
    overflow-x: hidden;
}

* {
    box-sizing: border-box;
}

body {
    font-family: 'Mazzard', sans-serif;
    margin: 0;
    background: #16161B;
    font-weight: 400;
}

p {
    margin: 0;
    color: #FFFFFF;
}

a {
    text-decoration: none;
    color: #FFFFFF;
}

input, textarea {
    border: none;
    outline: none;
    background: transparent;
    color: #FFFFFF;
}

button {
    color: #fff;
    border: none;
    outline: none;
    transition: 0.2s;
}

button:enabled {
    cursor: pointer;
}

.content {
    width: 96%;
    max-width: 1232px;
    margin: 0 auto;
    transition: 0.2s;
    position: relative;
    min-height: 100vh;
}